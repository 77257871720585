import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMarketplaceOffers = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 17, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 17.887 17.865' color={color} {...rest}>
      <g id='Group_17421' data-name='Group 17421' transform='translate(0 0)'>
        <g id='Group_17422' data-name='Group 17422' transform='translate(0 0)'>
          <path
            id='Path_4509'
            data-name='Path 4509'
            d='M9.105,8.234h0c.14-.14.209-.209.209-.349a.885.885,0,0,0-.07-.419,3.9,3.9,0,0,1-.7-2.3A4.046,4.046,0,0,1,15.455,2.3a3.9,3.9,0,0,1,1.117,2.791,4.072,4.072,0,0,1-1.186,2.861,3.952,3.952,0,0,1-2.861,1.186,3.549,3.549,0,0,1-2.233-.7h-.07a.461.461,0,0,0-.419-.07.384.384,0,0,0-.349.209.579.579,0,0,0,.14.837h.07a5.074,5.074,0,0,0,3,.907A5.164,5.164,0,1,0,7.5,5.164a5.3,5.3,0,0,0,.977,3c0,.14.349.209.628.07'
            transform='translate(-2.266 0)'
            fill={color}
          />
          <path
            id='Path_4510'
            data-name='Path 4510'
            d='M19.6,19.114c-.14-.558-.628-1.186-1.117-1.117a39.078,39.078,0,0,0-4.117.837.619.619,0,0,0-.419.7.7.7,0,0,0,.279.349.461.461,0,0,0,.419.07,36.072,36.072,0,0,1,3.768-.768c0,.07.07.14.07.209-2.024.768-5.024,2.233-5.234,2.373l-5.583.349c-.419-.14-.907-.349-1.326-.488a.578.578,0,1,0-.488,1.047,8.318,8.318,0,0,0,1.4.558.885.885,0,0,0,.419.07l5.722-.349a.256.256,0,0,0,.209-.07c.07,0,3.559-1.675,5.513-2.442a1.1,1.1,0,0,0,.488-1.326'
            transform='translate(-1.668 -5.437)'
            fill={color}
          />
          <path
            id='Path_4511'
            data-name='Path 4511'
            d='M6,16.482a8.94,8.94,0,0,0,1.4.768,11.091,11.091,0,0,0,1.814.279c.419.07.907.07,1.326.14.07.07.07.209.14.279H7.746a.6.6,0,0,0-.419.209.992.992,0,0,0-.14.419v.07a.6.6,0,0,0,.209.419.992.992,0,0,0,.419.14H11.3a.55.55,0,0,0,.558-.558c0-1.256-.419-1.884-1.117-2.024-.558-.07-1.186-.14-1.675-.209-.558-.07-1.117-.07-1.326-.14a1.912,1.912,0,0,1-.488-.279,2.829,2.829,0,0,0-.628-.349,3.652,3.652,0,0,0-1.884-.7H2.791a.55.55,0,0,0-.558.558v5.443H1.117V15.086h.419a.6.6,0,0,0,.558-.558v-.07a.6.6,0,0,0-.558-.558H.558A.55.55,0,0,0,0,14.458v6.978a.992.992,0,0,0,.14.419.513.513,0,0,0,.419.14H2.791a.55.55,0,0,0,.558-.558V15.994H4.676A3.059,3.059,0,0,1,6,16.482'
            transform='translate(0 -4.2)'
            fill={color}
          />
          <path
            id='Path_4512'
            data-name='Path 4512'
            d='M13.552,8.8A2.652,2.652,0,1,0,10.9,6.152,2.651,2.651,0,0,0,13.552,8.8m0-4.187a1.535,1.535,0,1,1-1.535,1.535,1.549,1.549,0,0,1,1.535-1.535'
            transform='translate(-3.294 -1.058)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconMarketplaceOffers
