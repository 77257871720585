import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { wethImagePath } from 'constants/index'
import useReserves, { Reserve } from 'hooks/common/useReserves'
import isEmpty from 'lodash/isEmpty'
import { useBalances } from 'modules/bend/hooks/useBalances'
import { useWethData, WethSteps } from 'modules/bend/hooks/useWeth'
import { useTranslation } from 'next-i18next'
import { Box, Flex, Text } from 'rebass/styled-components'
import { SliderContextProvider } from 'theme/ui/forms/slider'
import { lendPoolIcon } from 'utils'

import WrapIconElement from 'components/common/wrap-icon-element'

import { WrapStep4 } from './steps/step4'
import { WrapStep5 } from './steps/step5'
import { WrapStep6 } from './steps/step6'
import { WrapStep7 } from './steps/step7'
import { WrapStep8 } from './steps/step8'
import { WrapStep9 } from './steps/step9'
import { WrapStep10 } from './steps/step10'
import { WrapStep11 } from './steps/step11'
import { WrapStep1, WrapStep2, WrapStep3 } from './steps'

interface IWrapWizard {
  isDashboard?: boolean
}

export const WrapWizard: React.FC<IWrapWizard> = ({ isDashboard = false }) => {
  const { t } = useTranslation()
  const { data } = useReserves(false)

  const { account } = useWeb3React()
  const balances = useBalances()

  const { screenState, setScreenState, txHash, handleWrap, handleUnWrap, errorMsg, formatedWethBalance, txAmount } = useWethData({ account: account ?? '' })

  const reserves = useMemo(() => {
    if (!data || isEmpty(data)) return []
    return data.map((reserve: Reserve) => ({
      key: `${reserve.id}`,
      id: reserve.id,
      assetName: reserve.symbol,
      assetIcon: lendPoolIcon(reserve.underlyingAsset),
      assetAddress: reserve.underlyingAsset,
      walletBalance: '-',
      decimals: reserve.decimals,
      isFrozen: reserve.isFrozen
    }))
  }, [data])

  const handleShowWrap = () => {
    setScreenState(WethSteps.WETH_WRAP_START)
  }

  const handleShowUnWrap = () => {
    setScreenState(WethSteps.WETH_UNWRAP_START)
  }

  const handleBackToBalances = () => {
    setScreenState(WethSteps.WALLET_BALLANCES)
  }

  return (
    <Flex fontWeight='bold' color='black' flexDirection='column' mt={isDashboard ? -5 : 0}>
      {WethSteps.WALLET_BALLANCES === screenState && <Text fontSize={isDashboard ? 'lg' : 13}>{t('wallet.label.wallet-balances')}</Text>}
      {screenState > 1 && screenState < 7 && (
        <Flex justifyContent='space-between'>
          <Text fontSize={isDashboard ? 'lg' : 13}>
            <span dangerouslySetInnerHTML={{ __html: t('wallet.convert.eth-to-weth') }} />
          </Text>
          {screenState === 2 && (
            <Box onClick={() => setScreenState(WethSteps.WETH_UNWRAP_START)}>
              <WrapIconElement assetIconPath={reserves[0].assetIcon} wrappingAssetIconPath={wethImagePath} />
            </Box>
          )}
        </Flex>
      )}
      {screenState > 6 && (
        <Flex justifyContent='space-between'>
          <Text fontSize={isDashboard ? 'lg' : 13}>
            <span dangerouslySetInnerHTML={{ __html: t('wallet.convert.weth-to-eth') }} />
          </Text>
          {screenState === 7 && (
            <Box onClick={() => setScreenState(WethSteps.WETH_WRAP_START)}>
              <WrapIconElement assetIconPath={wethImagePath} wrappingAssetIconPath={reserves[0].assetIcon} />
            </Box>
          )}
        </Flex>
      )}
      <SliderContextProvider>
        {(() => {
          switch (screenState) {
            case WethSteps.WETH_UNWRAP_START:
              return <WrapStep7 handleBack={handleBackToBalances} handleUnWrap={handleUnWrap} balance={formatedWethBalance} />
            case WethSteps.WETH_UNWRAP_CONFIRM:
              return <WrapStep8 />
            case WethSteps.WETH_UNWRAP_PROCESSING:
              return <WrapStep9 txHash={txHash} />
            case WethSteps.WETH_UNWRAP_SUCCESS:
              return <WrapStep10 txHash={txHash} handleBack={handleBackToBalances} txAmount={txAmount} />
            case WethSteps.WETH_UNWRAP_FAIL:
              return <WrapStep11 txHash={txHash} handleBack={handleBackToBalances} errorMsg={errorMsg} />
            case WethSteps.WETH_WRAP_START:
              return <WrapStep2 handleBack={handleBackToBalances} handleWrap={handleWrap} balance={balances?.eth?.dp(4, 1)} isDashboard={isDashboard} />
            case WethSteps.WETH_WRAP_CONFIRM:
              return <WrapStep3 />
            case WethSteps.WETH_WRAP_PROCESSING:
              return <WrapStep4 txHash={txHash} />
            case WethSteps.WETH_WRAP_SUCCESS:
              return <WrapStep5 txHash={txHash} handleBack={handleBackToBalances} txAmount={txAmount} />
            case WethSteps.WETH_WRAP_FAIL:
              return <WrapStep6 txHash={txHash} handleBack={handleBackToBalances} errorMsg={errorMsg} />
            default:
            case WethSteps.WALLET_BALLANCES:
              return <WrapStep1 reserves={reserves} balances={balances} handleWrap={handleShowWrap} handleUnWrap={handleShowUnWrap} isDashboard={isDashboard} />
          }
        })()}
      </SliderContextProvider>
    </Flex>
  )
}
