interface errorInterface {
  errorMessage: string
}

const contractErrorCodes: {
  [key: string]: any
} = {
  //validation & check errors
  '301': 'Amount must be greater than 0',
  '302': 'Action requires an active reserve',
  '303': 'Action cannot be performed because the reserve is frozen',
  '304': 'User cannot withdraw more than the available balance',
  '305': 'Borrowing is not enabled',
  '306': 'The collateral balance is 0',
  '307': 'Health factor is lesser than the liquidation threshold',
  '308': 'There is not enough collateral to cover a new borrow',
  '309': 'There is no debt to be repaid',
  '310': 'The NFT not active', //VL_NO_ACTIVE_NFT
  '311': 'The NFT is frozen', //VL_NFT_FROZEN
  '312': 'User did not borrow the specified currency',
  '313': 'Invalid health factor', //VL_INVALID_HEALTH_FACTOR
  '314': 'Invalid owner address', //VL_INVALID_ONBEHALFOF_ADDRESS
  '315': 'Invalid target address', //VL_INVALID_TARGET_ADDRESS
  '316': 'Invalid reserve address', //VL_INVALID_RESERVE_ADDRESS
  '317': 'The selected loan is not owned by the user', //VL_SPECIFIED_LOAN_NOT_BORROWED_BY_USER
  '318': 'The selected reserve is not borrowed by user', //VL_SPECIFIED_RESERVE_NOT_BORROWED_BY_USER
  '319': 'Health factor is higher than the liquidation threshold', //VL_HEALTH_FACTOR_HIGHER_THAN_LIQUIDATION_THRESHOLD
  //lend pool errors
  '400': 'The caller of the function is not the lending pool configurator',
  '401': 'The lending pool is paused',
  '402': 'Adding more reserves is not allowed', //LP_NO_MORE_RESERVES_ALLOWED
  '403': 'Not a valid contract', //LP_NOT_CONTRACT
  '404': 'The loan amount does not exceed the liquidation threshold', //LP_BORROW_NOT_EXCEED_LIQUIDATION_THRESHOLD
  '405': 'The loan amount exceeds the liquidation price', //LP_BORROW_IS_EXCEED_LIQUIDATION_PRICE
  '406': 'No more NFTs allowed', //LP_NO_MORE_NFTS_ALLOWED
  '407': 'Invalid NFT amount', //LP_INVALIED_USER_NFT_AMOUNT
  '408': 'Inconsistent parameters', //LP_INCONSISTENT_PARAMS
  '409': 'The selected NFT is not used as collateral', //LP_NFT_IS_NOT_USED_AS_COLLATERAL
  '410': 'Invalid caller address (Not bToken)', //LP_CALLER_MUST_BE_AN_BTOKE
  '411': 'Invalid NFT amount', //LP_INVALIED_NFT_AMOUNT
  '412': 'The selected NFT is used as collateral', //LP_NFT_HAS_USED_AS_COLLATERAL
  '413': 'The delegation request failed', //LP_DELEGATE_CALL_FAILED
  '414': 'The amount is lower as the additional debt', //LP_AMOUNT_LESS_THAN_EXTRA_DEBT
  '415': 'The amount is lower than the redeem threshold', //LP_AMOUNT_LESS_THAN_REDEEM_THRESHOLD
  '416': 'The amount is too high (greater than max repay amount)', //LP_AMOUNT_GREATER_THAN_MAX_REPAY
  '417': 'The selected NFT token exceeds the max limit amount', //LP_NFT_TOKEN_ID_EXCEED_MAX_LIMIT
  '418': 'The total NFT supply exceeds the max limit amount', //LP_NFT_SUPPLY_NUM_EXCEED_MAX_LIMIT
  '419': 'Not a valid interceptor/caller', //LP_CALLER_NOT_VALID_INTERCEPTOR
  '420': 'Not a valid locker/caller', //LP_CALLER_NOT_VALID_LOCKER
  //lend pool loan errors
  '480': 'Invalid loan state', //LPL_INVALID_LOAN_STATE
  '481': 'Invalid loan amount', //LPL_INVALID_LOAN_AMOUNT
  '482': 'Invalid token amount', //LPL_INVALID_TAKEN_AMOUNT
  '483': 'The amount is too high (overflow)', //LPL_AMOUNT_OVERFLOW
  '484': 'The bid amount is lower than the liquidation price', //LPL_BID_PRICE_LESS_THAN_LIQUIDATION_PRICE
  '485': 'Bid amount is lower than the highest bid', //LPL_BID_PRICE_LESS_THAN_HIGHEST_PRICE
  '486': 'The redeem period has ended', //LPL_BID_REDEEM_DURATION_HAS_END
  '487': 'Invalid bidder address', //LPL_BID_USER_NOT_SAME
  '488': 'The repay amount is insuficient', //LPL_BID_REPAY_AMOUNT_NOT_ENOUGH
  '489': 'The auction has ended', //LPL_BID_AUCTION_DURATION_HAS_END
  '490': 'The auction is still in progress', //LPL_BID_AUCTION_DURATION_NOT_END
  '491': 'The bid amount cannot be lower than the loan amount', //LPL_BID_PRICE_LESS_THAN_BORROW
  '492': 'Invalid bidder address', //LPL_INVALID_BIDDER_ADDRESS
  '493': 'The amount is lower than the bid fine', //LPL_AMOUNT_LESS_THAN_BID_FINE
  '494': 'Invalid bid fine', //LPL_BID_INVALID_BID_FINE
  //common token errors
  '500': 'The caller of this function must be a lending pool',
  '501': 'Invalid amount to mint',
  '502': 'Invalid amount to burn',
  '503': 'Approved allowance too low for the amount, increase the allowance to continue' // CT_BORROW_ALLOWANCE_NOT_ENOUGH
}

export const handleError = ({ errorMessage }: errorInterface) => {
  if (typeof errorMessage !== 'string') return 'error'
  // APE staking V2
  if (errorMessage.includes('execution reverted: nftVault: already paired with bakc')) return 'Already paired with bakc'
  if (errorMessage.includes('execution reverted: NftVault: not ape')) return 'Not ape'
  if (errorMessage.includes('execution reverted: NftVault: caller not ape')) return 'Caller not ape'
  if (errorMessage.includes('execution reverted: nftVault: invalid delegate')) return 'Invalid delegate'
  if (errorMessage.includes('execution reverted: nftVault: only owner can delegate')) return 'Only owner can delegate'
  if (errorMessage.includes('execution reverted: nftVault: nft already staked')) return 'NFT already staked'
  if (errorMessage.includes('execution reverted: nftVault: invalid tokenIds')) return 'Invalid tokenIDs'
  if (errorMessage.includes('execution reverted: nftVault: caller must be nft owner')) return 'Caller must be nft owner'
  if (errorMessage.includes('execution reverted: nftVault: caller must be bayc staker')) return 'Caller must be bayc staker'
  if (errorMessage.includes('execution reverted: nftVault: caller must be mayc staker')) return 'Caller must be mayc staker'
  if (errorMessage.includes('execution reverted: nftVault: caller must be nft staker')) return 'Caller must be nft staker'
  if (errorMessage.includes('execution reverted: nftVault: not bayc or mayc')) return 'Not BAYC or MAYC'
  if (errorMessage.includes('execution reverted: nftVault: invalid staker')) return 'Invalid staker'
  if (errorMessage.includes('execution reverted: nftVault: invalid token id')) return 'Invalid token ID'
  if (errorMessage.includes('execution reverted: nftVault: staker must be same')) return 'Staker must be same'
  if (errorMessage.includes('execution reverted: StNft: nft not acceptable')) return 'NFT not acceptable'
  if (errorMessage.includes('execution reverted: stNft: only owner can burn')) return 'Only owner can burn'
  if (errorMessage.includes('execution reverted: stNft: invalid tokenId_')) return 'Invalid token ID'
  if (errorMessage.includes('execution reverted: stNft: staker index out of bounds')) return 'Staker index out of bounds'
  if (errorMessage.includes('execution reverted: stNft: only owner can delegate')) return 'Only owner can delegate'
  if (errorMessage.includes('execution reverted: BendCoinPool: caller is not staker')) return 'Caller is not staker'
  if (errorMessage.includes('execution reverted: BendCoinPool: withdraw failed')) return 'Withdraw failed'
  if (errorMessage.includes('execution reverted: BendNftPool: not ape')) return 'Not ape'
  if (errorMessage.includes('execution reverted: BendNftPool: caller is not staker')) return 'Caller is not staker'
  if (errorMessage.includes('execution reverted: BendNftPool: empty tokenIds')) return 'Empty tokenIDs'
  if (errorMessage.includes('execution reverted: BendNftPool: invalid token owner')) return 'Invalid token owner'
  if (errorMessage.includes('execution reverted: BendNftPool: invalid token staker')) return 'Invalid token staker'
  if (errorMessage.includes('execution reverted: BendNftPool: not ape nft')) return 'Not ape nft'
  // APE staking V2 END
  if (errorMessage.includes('user rejected transaction')) return 'User rejected transaction'
  if (errorMessage.includes('ACTION_REJECTED')) return 'User rejected transaction'
  if (errorMessage.includes('execution reverted: ERC20: insufficient allowance')) return 'Insufficient allowance'
  if (errorMessage.includes('execution reverted: SafeERC20: low-level call failed')) return 'SafeERC20: low-level call failed'
  if (errorMessage.includes('execution reverted: Adapter: WETH Insufficient')) return 'Offer maker has insufficient WETH balance'
  if (errorMessage.includes('execution reverted: Order: matching order expired')) return 'Order expired'
  if (errorMessage.includes('execution reverted: ERC20: transfer amount exceeds balance')) return 'Buyer balance too low'
  if (errorMessage.includes('execution reverted: Interceptor: no BNFT')) return 'Offer on bound NFT'
  if (errorMessage.includes('user rejected signing')) return 'User rejected signing'
  if (errorMessage.includes('execution reverted: 308')) return contractErrorCodes[308]
  if (errorMessage.includes('execution reverted: 418')) return contractErrorCodes[418]
  if (errorMessage.includes('execution reverted: 503')) return contractErrorCodes[503]
  if (errorMessage.includes('insufficient funds for intrinsic transaction cost')) return 'Insufficient funds for intrinsic transaction cost'
  if (errorMessage.includes('Order: matching order expired')) return 'Order: matching order expired'
  if (!errorMessage?.includes('execution reverted: ')) return errorMessage
  const errorCode = errorMessage.replace('execution reverted: ', '')
  return contractErrorCodes[errorCode] ?? errorMessage
}
