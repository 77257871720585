import React from 'react'

interface IIcon {
  color?: string
  size?: number
}

const IconMarketplaceCart = React.forwardRef<SVGSVGElement, IIcon>(({ color = 'currentColor', size = 21, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 20.326 21.126' color={color} {...rest}>
      <g id='Group_15966' data-name='Group 15966' transform='translate(0 0)'>
        <g id='Group_15967' data-name='Group 15967'>
          <path
            id='Path_4416'
            data-name='Path 4416'
            d='M122.913,382.711a2.667,2.667,0,1,0,2.667,2.667,2.67,2.67,0,0,0-2.667-2.667m0,3.734a1.067,1.067,0,1,1,1.067-1.067,1.068,1.068,0,0,1-1.067,1.067'
            transform='translate(-115.284 -366.92)'
            fill={color}
          />
          <path
            id='Path_4417'
            data-name='Path 4417'
            d='M314.264,382.711a2.667,2.667,0,1,0,2.667,2.667,2.67,2.67,0,0,0-2.667-2.667m0,3.734a1.067,1.067,0,1,1,1.067-1.067,1.068,1.068,0,0,1-1.067,1.067'
            transform='translate(-298.74 -366.92)'
            fill={color}
          />
          <path
            id='Path_4418'
            data-name='Path 4418'
            d='M20.152,5.21a.8.8,0,0,0-.626-.3H5.514L4.787,1.232A.8.8,0,0,0,4.146.6L.945.013A.8.8,0,0,0,.656,1.588l2.666.489,2.348,11.87a.8.8,0,0,0,.785.645h11.2a.8.8,0,0,0,.78-.62l1.867-8.082a.8.8,0,0,0-.153-.678m-3.13,7.78H7.113L5.831,6.509H18.519Z'
            transform='translate(0 0)'
            fill={color}
          />
        </g>
      </g>
    </svg>
  )
})

export default IconMarketplaceCart
