import React, { useMemo } from 'react'
import { rem } from 'polished'
import { createGlobalStyle, DefaultTheme } from 'styled-components'

import { IUseTheme } from '../constants/types'

import lightTheme from './colors/light'
import { DarkTheme, LightTheme } from './colors'
import fonts from './fonts'

const useDefaultTheme = (): DefaultTheme => {
  const breakpoints: string[] = React.useMemo(() => ['375px', '768px', '1024px', '1366px', '1440px', '1600px'], [])
  const darkMode = React.useMemo(() => false, [])
  const fonts = React.useMemo(
    () => ({
      body: "'Metropolis', sans-serif",
      header: "'Metropolis', sans-serif"
    }),
    []
  )

  const fontWeight = React.useMemo(
    () => ({
      thin: 100,
      mediumlight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }),
    []
  )

  const fontSizes = React.useMemo(
    () => ({
      xs: rem('9px'),
      xss: rem('10px'),
      xsm: rem('11px'),
      sm: rem('12px'),
      s: rem('11px'),
      md: rem('13px'),
      mdl: rem('14px'),
      lg: rem('15px'),
      lgm: rem('18px'),
      xl: rem('20px'),
      xxl: rem('24px'),
      xxxl: rem('32px'),
      xxxxl: rem('36px'),
      xxxxxl: rem('42px'),
      xxxxxxl: rem('58px'),
      xxxxxxxl: rem('64px')
    }),
    []
  )

  const sizes = React.useMemo(
    () => ({
      contentWide: 1680,
      content: 1400,
      content2: 1150,
      sidebarMin: 285,
      sidebarMax: 328
    }),
    []
  )

  const space = React.useMemo(
    () => ({
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      7: '7px',
      8: '8px',
      9: '9px',
      xxs: '2px',
      xs: '5px',
      sm: '8px',
      md: '12px',
      lg: '16px',
      xl: '20px',
      xxl: '28px',
      xxxl: '36px',
      xxxxl: '44px',
      xxxxxl: '70px',
      content: 1400,
      content2: 1150
    }),
    []
  )

  const borderRadius = React.useMemo(
    () => ({
      0: 0,
      xs: 4,
      sm: 8,
      md: 16,
      lg: 32
    }),
    []
  )

  const radii = React.useMemo(
    () => ({
      icon: 36,
      xs: 4,
      sm: 8,
      md: 12
    }),
    []
  )

  const cardTitle = React.useMemo(
    () => ({
      fontFamily: fonts.header,
      fontSize: [fontSizes.xxxxl],
      borderRadius: radii['sm'],
      textAlign: 'center',
      px: 30,
      py: 17,
      justifyContent: 'center',
      alignItems: 'center'
    }),
    [fontSizes.xxxxl, fonts.header, radii]
  )

  const tableRow = React.useMemo(
    () => ({
      alignItems: 'center',
      fontSize: fontSizes.lg,
      fontWeight: fontWeight.regular,
      py: [30, 30, 8],
      px: 30,
      width: '100%'
    }),
    [fontSizes.lg, fontWeight.regular]
  )

  const [headerHeight, setHeaderHeight] = React.useState(0)

  const transitionAnimation = useMemo(
    () => ({
      transitionProperty: 'all',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'ease-in-out'
    }),
    []
  )
  return {
    headerHeight,
    setHeaderHeight,
    breakpoints,
    darkMode,
    colors: darkMode ? DarkTheme : LightTheme,
    fonts,
    fontWeight,
    fontSizes,
    lineHeights: fontSizes,
    space,
    spacer: space,
    radii,
    borderRadius,
    transitionAnimation,
    boxShadows: {
      sm: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
    },
    sizes,
    elipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: ['nowrap'],
      overflow: 'hidden'
    },
    variants: {
      drawer: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: '260px',
        height: '100%',
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        zIndex: 2023
      },
      card: {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [15, 15, 25],
        py: [15, 15, 25]
      },
      'card-sticky': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 6px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [15, 15, 25],
        py: [15, 15, 25]
      },
      'card-sticky-custom-top': {
        /*  position: 'relative', */
        borderRadiusBottomRight: 0,
        borderRadiusBottomLeft: 0,
        borderRadiusTopRight: radii['sm'],
        borderRadiusTopLeft: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        px: [15, 15, 25],
        py: [15, 15, 25]
      },
      'card-sticky__custom-top__single': {
        borderRadiusBottomRight: 0,
        borderRadiusBottomLeft: 0,
        borderRadiusTopRight: radii['sm'],
        borderRadiusTopLeft: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 6px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [15, 15, 25],
        pb: [15, 15, 25],
        pt: [25, 25, 25],
        mt: 10
      },
      'card-sticky-custom': {
        width: '100%',
        borderRadiusBottomRight: radii['sm'],
        borderRadiusBottomLeft: radii['sm'],
        borderRadiusTopRight: 0,
        borderRadiusTopLeft: 0,
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 6px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [15, 15, 25]
      },
      'card-dashboard': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'card-banner-staking': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'card-banner-my-stakings': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [15]
      },
      'card-banner-2': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [15]
      },
      'card-banner-3': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20],
        py: [20]
      },
      'card-banner-staking-2': {
        position: 'relative',
        alignItems: 'center',
        color: 'white',
        justifyContent: 'space-between',
        lineHeight: 'md',
        width: '100%',
        bg: LightTheme.blue[5],
        borderRadius: radii['sm'],
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [8]
      },
      'staking-row': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20],
        py: [20]
      },
      'staking-history-row': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20],
        py: [15],
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      },
      'card-banner': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'card-banner-floating': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'card-banner-1': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'classic-table-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        pt: [30, 30, 30, 30, 40],
        pb: [20, 20, 30, 30, 40],
        overflow: 'hidden'
      },
      'receiver-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 30, 30, 30],
        py: [30, 30, 30, 30, 30]
      },
      'landing-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'grid-card__dashboard': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0px 4px 8px 0px ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25],
        py: [20, 20, 25]
      },
      'grid-card__wrapper': {
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'grid-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        p: 0,
        pb: 15
      },
      'nft-list': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20],
        py: [20],
        '&:hover': {
          boxShadow: `0 2px 8px 2px ${darkMode ? DarkTheme.shadow[200] : LightTheme.shadow[200]}`,
          cursor: 'pointer'
        }
      },
      'nft-list__skeleton': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20],
        py: [20]
      },
      'nft-list__item': {
        fontSize: fontSizes.s,
        color: LightTheme.label,
        lineHeight: 'xl',
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      'grid-card__img': {
        position: 'relative',
        borderRadius: 'sm',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        overflow: 'hidden',
        width: '100%',
        '&:hover': {
          borderRadius: 'xs'
        }
      },
      'grid-card__img__check': {
        opacity: 0.5,
        position: 'absolute',
        left: 10,
        top: 10,
        color: 'primary',
        bg: 'white',
        p: 2,
        borderRadius: 32,
        borderColor: 'primary',
        borderWidth: 2,
        borderStyle: 'solid',
        zIndex: 1
      },
      'row-list__img__check': {
        opacity: 0.5,
        position: 'center',
        left: 10,
        right: 10,
        color: 'primary',
        bg: 'white',
        p: 2,
        minWidth: 18,
        borderRadius: 32,
        borderColor: 'primary',
        borderWidth: 2,
        borderStyle: 'solid',
        zIndex: 1
      },
      'grid-card__img__check-selected': {
        position: 'absolute',
        left: 10,
        top: 10,
        color: 'white',
        bg: 'primary',
        p: 2,
        borderRadius: 32,
        borderColor: 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        zIndex: 1
      },
      'row-list__img__check-selected': {
        position: 'center',
        left: 10,
        right: 10,
        color: 'white',
        bg: 'primary',
        p: 2,
        borderRadius: 32,
        borderColor: 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        zIndex: 1
      },
      'grid-card__img__tags': {
        display: 'grid',
        width: '100%',
        position: 'absolute',
        gridGap: '4px',
        justifyItems: 'flex-end',
        pt: 10,
        pr: 10,
        zIndex: 1
      },
      'grid-card__img__time-left': {
        color: 'black',
        fontSize: 'sm',
        bg: 'white',
        padding: 8,
        borderRadius: 'sm',
        position: 'absolute',
        left: 10,
        top: 10,
        lineHeight: 'sm',
        minWidth: 90,
        textAlign: 'center',
        cursor: 'default',
        zIndex: 101
      },
      'grid-card__img__time-left-staking': {
        color: 'black',
        fontSize: 'xs',
        bg: 'white',
        py: 4,
        px: 6,
        borderRadius: 'sm',
        position: 'absolute',
        left: 10,
        bottom: 10,
        lineHeight: 'sm',
        minWidth: 90,
        textAlign: 'center',
        cursor: 'default',
        zIndex: 101
      },
      'grid-card__img__notice__wrapper': {
        opacity: 0,
        position: 'absolute',
        left: [2, 2, 10],
        bottom: [5, 5, 10],
        right: [2, 2, 10],
        zIndex: 1,
        justifyContent: 'center',
        ...transitionAnimation
      },
      'grid-card__img__notice': {
        color: 'primary',
        bg: 'white',
        p: 2,
        borderRadius: 32,
        borderColor: 'primary',
        borderWidth: 2,
        borderStyle: 'solid',
        alignItems: 'center',
        fontSize: 'xsm',
        lineHeight: 'xsm',
        fontWeight: 'bold',
        pl: 3,
        pr: 5,
        gap: 5
      },
      'grid-card__img__lendpool__wrapper': {
        opacity: 0.9,
        position: 'absolute',
        left: [2, 2, 10],
        bottom: [5, 5, 10],
        right: [2, 2, 10],
        zIndex: 1,
        justifyContent: 'flex-end',
        ...transitionAnimation
      },
      'nft-list__img__lendpool__wrapper': {
        opacity: 0.9,
        position: 'absolute',
        bottom: [5, 5, 5],
        right: [2, 2, 5],
        zIndex: 1,
        justifyContent: 'flex-end',
        ...transitionAnimation
      },
      'grid-card__img__lendpool': {
        borderRadius: 32,
        alignItems: 'center',
        overflow: 'hidden',
        bg: 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'white'
      },
      'grid-card__img__copy': {
        color: 'white',
        fontSize: 'sm',
        bg: 'primary',
        p: 0,
        borderRadius: 'sm',
        position: 'absolute',
        right: 10,
        bottom: 10,
        lineHeight: 'sm',
        textAlign: 'center',
        '& button:hover': {
          opacity: 1
        }
      },
      'grid-card__img_order-prices': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.85) 100%)',
        width: '100%',
        color: 'black',
        p: 15,
        pb: 0,
        borderTopLeftRadius: 'sm',
        borderTopRightRadius: 'sm',
        flexDirection: 'column'
      },
      'grid-card__title': {
        width: '100%',
        px: 15,
        mt: 14,
        mb: 10,
        alignItems: 'center',
        justifyContent: 'space-between',
        a: {
          color: 'black'
        }
      },
      'grid-card__content': {
        width: '100%',
        px: 15
      },
      'grid-card__buttons': {
        mt: 15,
        px: 15,
        width: '100%'
      },
      card2: {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: 0,
        pt: 0,
        pb: 62
      },
      'card-statistics': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: 20,
        py: 16
      },
      'card-filter': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: 20,
        py: 16
      },
      card5: {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        pb: [20, 20, 25]
      },
      card9: {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'card5-modal': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        pb: [20, 20, 25]
      },
      'card6-modal': {
        position: 'relative',
        width: ['100%', '100%', 500],
        borderRadius: radii['sm'],
        borderBottomLeftRadius: [0, 0, radii['sm']],
        borderBottomRightRadius: [0, 0, radii['sm']],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        pb: [20, 20, 25]
      },
      card7: {
        width: '100%',
        px: [0, 0, 0],
        pt: 10,
        pb: 0
      },
      compounder: {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        p: 20
      },
      'card-timer': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.primary : LightTheme.primary,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [15, 15, 20, 40],
        py: 20
      },
      'dashboard-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 35],
        py: 25,
        justifyContent: 'space-between'
      },
      'selection-box': {
        borderRadius: radii['sm'],
        bg: darkMode ? 'transparent' : 'transparent',
        border: `1px solid ${LightTheme.grey[300]}`,
        justifyContent: 'center',
        px: 20,
        py: 5,
        fontWeight: fontWeight.regular,
        fontSize: fontSizes.md,
        zIndex: 300,
        height: 32,
        width: 155
      },
      'selection-menu': {
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        zIndex: 500,
        justifyContent: 'center',
        fontWeight: fontWeight.medium,
        fontSize: fontSizes.md,
        color: darkMode ? 'white' : 'black',
        transitionProperty: 'all',
        transitionDuration: '0.3s',
        transitionDelay: '0.2s',
        transitionTimingFunction: 'ease-in'
      },
      'card-title-wrapper': {
        position: 'absolute',
        top: -29,
        left: 0,
        width: '100%',
        px: [20, 20, 40]
      },
      'card-body': {
        position: 'relative',
        width: '100%',
        bg: 'transparent',
        px: [20],
        pt: 0,
        zIndex: 100
      },
      'card-body-modal': {
        position: 'relative',
        width: '100%',
        bg: 'transparent',
        px: [0],
        pt: 0,
        zIndex: 100
      },
      'card-title-primary': {
        ...cardTitle,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        bg: darkMode ? DarkTheme.primary : LightTheme.primary
      },
      'card-sidebar': {
        mt: 10,
        // mx: -30,
        px: 30
      },
      'card-sidebar-2': {
        mt: 50,
        mb: 20,
        px: 20
      },
      'notification-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: [20, 20, 25, 25, 25],
        py: [20, 20, 25, 25, 25]
      },
      'landing-info-card-1': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: 30,
        py: 30
      },
      'more-info-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        px: 30,
        py: 30,
        backgroundColor: 'blue.1400',
        color: 'white',
        gap: [30, 30, 30],
        pt: [30, 30, 50],
        pb: [20, 20, 50],
        mb: 30,
        flexDirection: 'column',
        overflow: 'hidden'
      },
      'landing-banner-card': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'testnet-notification': {
        width: '100%',
        background: 'white',
        border: `1px solid ${LightTheme.primary}`,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        maxWidth: 328,
        borderRadius: radii['sm'],
        overflow: 'hidden',
        position: 'fixed',
        right: [0, 'calc(50% - 163px)', 20],
        bottom: [0, 86, 20],
        zIndex: 200
      },
      'terms-and-service-notification': {
        width: [320, 320, 360],
        background: 'white',
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        maxWidth: 360,
        borderRadius: radii['sm'],
        zIndex: 1002,
        transitionProperty: 'all',
        transitionDelay: '0.2s',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out'
      },
      'twitter-notification': {
        width: [320, 320, 420, 460],
        alignItems: 'center',
        background: 'white',
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        maxWidth: 420,
        borderRadius: radii['sm'],
        zIndex: 200,
        transitionProperty: 'all',
        transitionDelay: '0.2s',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out'
      },
      'orange-wrapper': {
        borderRadius: 'xs',
        border: `1px solid ${LightTheme.orange[200]}`,
        fontSize: 'sm',
        textAlign: 'center',
        lineHeight: 'lg',
        mt: 10,
        py: 15,
        px: [20, 20, 40],
        flexDirection: 'column',
        gap: 20
      },
      'orange-frame-bdin': {
        mt: 30,
        mb: 30,
        borderRadius: 'xs',
        borderColor: LightTheme.orange[200],
        borderWidth: 1,
        borderStyle: 'solid',
        color: LightTheme.orange[200],
        fontSize: 'sm',
        p: 20,
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
      },
      'red-frame-bdin': {
        mt: 30,
        mb: 30,
        borderRadius: 'xs',
        borderColor: LightTheme.red[1000],
        borderWidth: 1,
        borderStyle: 'solid',
        color: LightTheme.red[1000],
        fontSize: 'sm',
        p: 20,
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center'
      },
      modal: {
        bg: darkMode ? DarkTheme.black : LightTheme.white,
        borderRadius: radii['sm'],
        transitionProperty: 'all',
        transitionDelay: '0.2s',
        transitionDuration: '0.3s',
        transitionTimingFunction: 'ease-in-out',
        minWidth: 220
      },
      'modal-body': {
        p: [20, 20, 30]
      },
      'connect-to-modal': {
        p: 10
      },
      'filter-label-v2': {
        cursor: 'pointer',
        borderRadius: 'sm',
        px: 0,
        py: 5,
        transition: 'all 0.3s',
        '&:hover': {
          opacity: 0.8,
          svg: {
            path: {
              fill: 'primary'
            }
          }
        }
      },
      'filter-label': {
        borderRadius: 'sm',
        px: 15,
        py: 5,
        transition: 'all 0.3s',
        '&:hover': {
          bg: 'blue.100',
          svg: {
            path: {
              fill: 'primary'
            }
          }
        }
      },
      'filter-label-disabled': {
        borderRadius: 'sm',
        color: 'grey.300',
        px: 15,
        py: 5,
        cursor: 'not-allowed',
        transition: 'all 0.3s',
        '&:hover': {
          bg: 'grey.100',
          color: 'grey.500',
          svg: {
            path: {
              fill: 'grey.500'
            }
          }
        }
      },
      'filter-label-disabled-v2': {
        borderRadius: 'sm',
        color: 'grey.300',
        px: 0,
        py: 5,
        cursor: 'not-allowed',
        transition: 'all 0.3s',
        '&:hover': {
          bg: 'grey.100',
          color: 'grey.500',
          svg: {
            path: {
              fill: 'grey.500'
            }
          }
        }
      },
      'light-label': {
        bg: 'grey.5',
        color: 'grey.500',
        borderRadius: 'sm',
        gap: 5,
        alignItems: 'center',
        p: 10,
        fontSize: 'sm',
        lineHeight: 'lg',
        mt: 10,
        mb: -10,
        justifyContent: 'center'
      },
      'light-label-none': {
        bg: 'green.100',
        borderRadius: 'sm',
        gap: 5,
        alignItems: 'center',
        p: 10,
        fontSize: 'sm',
        lineHeight: 'lg',
        mt: 10,
        mb: -10,
        justifyContent: 'center'
      },
      'yellow-label': {
        bg: 'yellow.300',
        color: 'grey.800',
        borderRadius: 'sm',
        gap: 5,
        alignItems: 'center',
        p: 10,
        fontSize: 'sm',
        lineHeight: 'lg',
        mt: 10,
        mb: -10,
        justifyContent: 'center'
      },
      'footer-link': {
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.75,
          transition: 'all 0.3s ease-in-out'
        }
      },
      'footer-link-app': {
        color: 'black',
        '& svg': {
          color: 'headerLabel'
        },
        '&:hover': {
          cursor: 'pointer',
          '& svg': {
            color: 'white'
          }
        }
      },
      'link-banner': {
        cursor: 'pointer'
      },
      link: {
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.75,
          transition: 'opacity .2s ease-out'
        }
      },
      'footer-text': {
        ml: [0, 0, 0, 0, -10],
        color: lightTheme.white,
        fontSize: 13,
        fontWeight: 'bold'
      },
      table: {
        flexDirection: 'column',
        width: '100%',
        minWidth: 295,
        bg: darkMode ? DarkTheme.grey[100] : 'transparent',
        overflowY: 'auto'
      },
      'table-classic': {
        flexDirection: 'column',
        width: '100%',
        bg: 'transparent'
      },
      'table-header': {
        flexDirection: 'row',
        fontWeight: fontWeight.medium,
        width: '100%',
        color: darkMode ? DarkTheme.white : LightTheme.grey[300],
        fontSize: fontSizes.sm,
        px: 30,
        py: 18.5,
        maxHeight: 62,
        justifyContent: 'space-between',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        mb: 10
      },
      'table-header-classic': {
        flexDirection: 'row',
        fontWeight: fontWeight.medium,
        width: '100%',
        color: darkMode ? DarkTheme.white : LightTheme.grey[300],
        fontSize: fontSizes.sm,
        pb: 15,
        pt: 13,
        maxHeight: 52,
        justifyContent: 'space-between'
      },
      'table-header-column-mobile': {
        fontWeight: fontWeight.regular,
        color: darkMode ? DarkTheme.white : LightTheme.grey[300],
        fontSize: fontSizes.sm
      },
      'table-row': {
        ...tableRow,
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        mb: 10,
        py: 20
      },
      'table-row-closed': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: 10,
        px: 20,
        width: '100%',
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        mb: 10,
        borderRadius: radii['sm']
      },
      'table-row-open': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: 10,
        px: 20,
        width: '100%',
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        mb: 10,
        borderRadius: radii['sm'],
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      },
      'table-row-subrow': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: 10,
        px: 20,
        width: '100%',
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        mb: 10
      },
      'table-row-classic': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: [15, 15, 8],
        width: '100%'
      },
      'table-row-wrappers': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: [15, 15, 8],
        px: 20,
        mb: 10,
        width: '100%',
        borderRadius: radii['sm'],
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'table-row-2': {
        alignItems: 'center',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: [15, 15, 8],
        px: 20,
        mb: 10,
        width: '100%',
        borderRadius: radii['sm'],
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'table-row-3': {
        alignItems: 'flex-start',
        fontSize: fontSizes.lg,
        fontWeight: fontWeight.regular,
        py: [15, 15, 8],
        px: 20,
        mb: 10,
        width: '100%',
        borderRadius: radii['sm'],
        boxShadow: `0 2px 4px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
      },
      'info-display': {
        flexDirection: ['row', 'row', 'row'],
        justifyContent: ['space-between'],
        alignItems: ['flex-start', 'flex-start', 'center'],
        width: '100%',
        p: [20, 20, 25]
      },
      'info-display-mobile-fit': {
        flexDirection: ['column', 'column', 'row'],
        justifyContent: ['space-between'],
        alignItems: ['flex-start', 'flex-start', 'center'],
        width: '100%',
        p: 30
      },
      'notification-wrapper': {
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        fontSize: 'md',
        lineHeight: 'md',
        py: 15,
        px: [20, 20, 20, 20, 20, 0]
      },
      'notification-wrapper__mobile': {
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        fontSize: 'sm',
        lineHeight: 'sm',
        py: 10
      },
      'notification-wrapper__container': {
        width: '100%',
        px: 20,
        // maxWidth: 1400,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: ['column', 'column', 'column', 'row']
      },
      'notification-wrapper__container__mobile': {
        width: '100%',
        px: 20,
        // maxWidth: 1400,
        justifyContent: 'space-between',
        flexDirection: ['column']
      },
      'notification-wrapper__container__title': {
        flexDirection: ['column', 'column', 'column', 'row'],
        alignItems: ['center']
      },
      'notification-wrapper__container__title__mobile': { gap: 5 },
      'notification-wrapper__container__content': {
        my: [20, 20, 20, 0],
        flexDirection: ['column', 'column', 'column', 'row'],
        alignItems: ['center'],
        flex: 1,
        mx: 25
      },
      'notification-wrapper__container__content__mobile': {
        lineHeight: 'lg'
      },
      breadcrumb: {
        fontSize: [fontSizes.sm, fontSizes.sm, fontSizes.md],
        lineHeight: [fontSizes.sm, fontSizes.sm, fontSizes.md],
        fontWeight: fontWeight.bold,
        alignItems: 'center',
        mx: [space.sm, space.sm, space.lg]
      },
      'sidebar-link': {
        width: '100%',
        textAlign: 'left',
        flex: 1,
        bg: 'bg.inctiveStakeNav',
        borderColor: 'bg.inctiveStakeNav',
        color: 'black',
        fontWeight: 'bold',
        fontSize: 'md',
        px: 20,
        py: 16,
        borderRadius: 'sm',
        '& svg': {
          transition: 'all 0.3s',
          color: 'grey.300'
        },
        '&:hover': {
          cursor: 'pointer',
          '&:after': {
            left: 0,
            width: '100%',
            bg: ['white', 'white', 'white', 'white', 'primary']
          },
          '& svg': {
            color: 'primary'
          }
        }
      },
      'mobile-navigation-bottom': {
        position: 'fixed',
        width: '100%',
        bg: 'white',
        height: 52,
        bottom: 0,
        left: 0,
        zIndex: 500,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        boxShadow: `0px 0px 12px 0px ${darkMode ? DarkTheme.shadow[200] : LightTheme.shadow[200]}`,
        px: 10
      },
      'classic-table-card-1': {
        position: 'relative',
        width: '100%',
        borderRadius: radii['sm'],
        bg: darkMode ? DarkTheme.white : LightTheme.white,
        boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
        pb: [20, 20, 30, 30, 40]
      },
      'more-menu': {
        width: '100%',
        fontSize: 'sm',
        a: {
          color: 'black',
          px: 10,
          py: 5,
          transition: 'all 0.2s',
          svg: {
            color: 'grey.300',
            transition: 'all 0.2s'
          },
          '&:hover': { bg: 'blue.100', borderRadius: 'sm' },
          '&:hover svg': {
            color: 'primary'
          }
        }
      },
      'nft-overview__nft-images__wrapper': {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 5
      },
      'nft-overview__nft-images__plus': {
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary',
        fontWeight: 'bold',
        border: '1px solid',
        borderColor: 'primary',
        borderRadius: 'sm',
        height: 42,
        width: 42,
        minWidth: 42
      },
      'nft-overview__nft-images__wrapper__image_wrapper': {
        borderRadius: 'sm',
        height: 42,
        width: 42,
        minWidth: 42,
        overflow: 'hidden'
      },
      'nft-overview__nft-images__wrapper__image_tooltip': {
        fontSize: 'xs'
      }
    },
    buttons: {
      default: {
        opacity: 1,
        transition: 'opacity .2s ease-out',
        bg: darkMode ? DarkTheme.black : LightTheme.black,
        color: darkMode ? DarkTheme.white : LightTheme.white,
        borderRadius: radii['sm'],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: darkMode ? DarkTheme.black : LightTheme.black,
        fontSize: `calc(${fontSizes.sm} - 0.05rem)`,
        lineHeight: fontSizes.sm,
        px: 30,
        py: 8,
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.75
        },
        '&:disabled': {
          opacity: 0.2,
          cursor: 'default'
        }
      },
      'banner-button': {
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.75
        },
        '&:disabled': {
          opacity: 0.2,
          cursor: 'default'
        }
      },
      outlined: {
        opacity: 1,
        transition: 'opacity .2s ease-out',
        bg: 'transparent',
        color: darkMode ? DarkTheme.black : LightTheme.black,
        borderRadius: radii['sm'],
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: darkMode ? DarkTheme.black : LightTheme.black,
        fontSize: `calc(${fontSizes.sm} - 0.05rem)`,
        lineHeight: fontSizes.sm,
        px: 30,
        py: 8,
        cursor: 'pointer',
        '&:hover': {
          opacity: 0.75
        },
        '&:disabled': {
          opacity: 0.2,
          cursor: 'default'
        }
      },
      filter: {
        border: '1px solid',
        fontSize: 'sm',
        lineHeight: 'sm',
        borderRadius: 'sm',
        py: 2,
        px: 15,
        transition: 'all 0.3s',
        '&:disabled': {
          opacity: 0.2,
          cursor: 'not-allowed'
        }
      },
      sidebar: {
        width: '100%',
        textAlign: 'left',
        flex: 1,
        bg: 'bg.inctiveStakeNav',
        borderColor: 'bg.inctiveStakeNav',
        color: 'black',
        fontWeight: 'bold',
        fontSize: 'sm',
        px: 20,
        py: 16,
        borderRadius: 'sm',
        '& svg': {
          transition: 'all 0.3s',
          color: 'grey.300'
        },
        '&:hover': {
          cursor: 'pointer',
          '&:after': {
            left: 0,
            width: '100%',
            bg: ['white', 'white', 'white', 'white', 'primary']
          },
          '& svg': {
            color: 'primary'
          }
        }
      }
    },
    'orange-frame-bdin': {
      borderRadius: 'xs',
      borderColor: LightTheme.orange[200],
      borderWidth: 1,
      borderStyle: 'solid',
      color: LightTheme.orange[200],
      fontSize: 'sm',
      p: 20,
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center'
    },
    'red-frame-bdin': {
      borderRadius: 'xs',
      borderColor: LightTheme.red[1000],
      borderWidth: 1,
      borderStyle: 'solid',
      fontSize: 'sm',
      p: 20,
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center'
    },
    'green-frame-bdin': {
      borderRadius: 'xs',
      borderColor: LightTheme.green[1000],
      borderWidth: 1,
      borderStyle: 'solid',
      color: LightTheme.green[1000],
      fontSize: 'sm',
      p: 20,
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center'
    },
    'grey-frame-bdin': {
      borderRadius: 'xs',
      borderColor: LightTheme.grey[300],
      borderWidth: 1,
      borderStyle: 'solid',
      color: LightTheme.orange[200],
      fontSize: 'sm',
      p: 20,
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      gap: 10
    },
    'orange-frame-small': {
      borderRadius: 'xs',
      borderColor: 'orange.200',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'orange.200',
      fontSize: 'sm',
      px: 10
    },
    'ecosystem-card': {
      position: 'relative',
      width: '100%',
      borderRadius: radii['sm'],
      bg: darkMode ? DarkTheme.white : LightTheme.white,
      boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`,
      padding: 20,
      flexDirection: 'column'
    },
    'app-notification-right': {
      position: 'relative',
      maxWidth: 280,
      width: 'auto',
      fontSize: 'sm',
      padding: 10,
      borderRadius: 'sm',
      cursor: 'default'
    },
    'button-badge': {
      position: 'absolute',
      top: -10,
      right: -10,
      width: 20,
      height: 20,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 8,
      borderRadius: 15
    },
    'staked-nfts__nft-images': {
      alignItems: 'center',
      justifyContent: 'center',
      color: 'primary',
      fontWeight: 'bold',
      border: '1px solid',
      borderColor: 'primary',
      borderRadius: 'icon'
    },
    'card__ful-vh': {
      position: 'relative',
      width: '100%',
      borderRadius: radii['sm'],
      bg: darkMode ? DarkTheme.white : LightTheme.white,
      boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
    },
    'leaving-benddao-modal': {
      position: 'relative',
      width: '100%',
      borderRadius: radii['sm'],
      bg: darkMode ? DarkTheme.white : LightTheme.white,
      boxShadow: `0 4px 8px 0 ${darkMode ? DarkTheme.shadow[100] : LightTheme.shadow[100]}`
    },
    'card__ful-vh__item': {
      position: 'relative',
      as: 'a',
      width: '100%',
      alignItems: 'center',
      px: [20],
      py: [15],
      '&:hover': {
        cursor: 'pointer',
        bg: lightTheme.grey[50]
      }
    },
    'card__ful-vh__item-no-fx': {
      position: 'relative',
      as: 'a',
      width: '100%',
      alignItems: 'center',
      px: [20],
      py: [15]
    },
    'card_full-vh__item_column': {
      fontWeight: 'bold',
      color: lightTheme.black,
      flex: 1,
      fontSize: fontSizes.sm
    },
    'card__ful-vh__table-header': {
      position: 'relative',
      width: '100%',
      px: [20],
      py: [15],
      lineHeight: 'xl'
    },
    'card__ful-vh__table-header-item': {
      width: '100%',
      alignItems: 'center',
      fontSize: 'sm',
      color: 'label',
      flex: 1,
      justifyContent: 'flex-start',
      lineHeight: 'md'
    },
    'card__ful-vh__listed-item': {
      width: '100%',
      alignItems: 'center',
      px: [20],
      py: [10],
      fontWeight: 'bold',
      fontSize: 'sm',
      lineHeight: 'sm',
      '&:hover': {
        cursor: 'pointer',
        bg: lightTheme.grey[50]
      }
    },
    'wrapper__no-records': {
      as: 'span',
      color: lightTheme.label,
      lineHeight: 'md',
      fontSize: 'md',
      textAlign: 'center',
      mt: 25,
      maxWidth: 240,
      flexDirection: 'column',
      gap: 10
    },
    'text-main__no-records': {
      fontSize: fontSizes.xxl,
      color: 'black',
      fontWeight: 'bold',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center'
    },
    'wrapper-img__no-records': {
      backgroundColor: lightTheme.primary,
      borderRadius: radii['icon'],
      height: 72,
      width: 72,
      overflow: 'hidden',
      minWidth: 72,
      justifyContent: 'center',
      alignItems: 'center',
      svg: {
        path: {
          color: 'white'
        }
      }
    },
    'card_full-vh__item_mobile': {
      width: '100%',
      flexDirection: 'column',
      fontSize: fontSizes.sm,
      color: lightTheme.label,
      lineHeight: fontSizes.xl
    }
  }
}

export const GlobalStyle = createGlobalStyle<IUseTheme>`
  /* Fonts */
  ${fonts}

  /* Global styles below */
  :root {
    --wcm-z-index: 8000 !important;
  }

  html {
    font-family: ${({ fonts }) => fonts.body};
    font-weight: ${({ fontWeight }) => fontWeight.medium};
    font-size: 16px;
    /* touch-action: none; */
  }

  body {
    /* position: fixed;
    top: 0; right: 0; bottom: 0; left: 0; */
    background-color: ${({ colors }) => colors.body};
    background-size: cover;
    line-height: ${rem('24px')};
    min-width: 360px;
    height: 100%;
    overflow-x: hidden;
    /* text-rendering: geometricPrecision; */

    &.modal-open,
    &.sidebar-open {
      overflow: hidden;
    }
  }

  input[type='number'] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  button, input, select, label, p, a, h1, h2, h3, h4, h5, h6, ul, li, div {}

  button {
    font-family: ${({ fonts }) => fonts.body};
  }

  strong {
    font-weight: ${({ fontWeight }) => fontWeight.bold}
  }

  primary-color {
    color: ${LightTheme.primary};
    font-weight: ${({ fontWeight }) => fontWeight.bold}
  }

  white-color {
    color: #FFFFFF;
    font-weight: ${({ fontWeight }) => fontWeight.bold};
    opacity: 1
  }

  grey-color {
    color: #8C9096;
  }

  primary-normal {
    color: ${LightTheme.primary};
  }

  light-blue {
    color: ${LightTheme.blue[1]};
  }

  red-color {
    color: ${LightTheme.red[1000]};
    font-weight: ${({ fontWeight }) => fontWeight.bold}
  }

  green-color-regular {
    color: ${LightTheme.green[1000]};
  }
  
  green-color {
    color: ${LightTheme.green[1000]};
    font-weight: ${({ fontWeight }) => fontWeight.bold}
  }
  
  yellow-color {
    color: ${LightTheme.yellow[1000]};
    font-weight: ${({ fontWeight }) => fontWeight.bold}
  }

  orange-color {
    color: ${LightTheme.orange[200]};
  }

  p {}

  a {
    color: ${({ colors }) => colors.blue[300]};
    text-decoration: none;
  }

  underline-black {
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
  
  
  underline-black:hover {
    opacity: 0.7;
  }

  a-punk {
    color: #9F0FF2;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }

  turquoise-color {
    color: #00B5A4;
    font-weight: bold;
  }

  /* SimpleBar React */

  [data-simplebar] {
    position: relative;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }

  .simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
  }

  .simplebar-mask {
    direction: inherit;
    position: absolute;
    overflow: hidden;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: auto !important;
    height: auto !important;
    z-index: 0;
  }

  .simplebar-offset {
    direction: inherit !important;
    box-sizing: inherit !important;
    resize: none !important;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
  }

  .simplebar-content-wrapper {
    direction: inherit;
    box-sizing: border-box !important;
    position: relative;
    display: block;
    height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
    width: auto;
    max-width: 100%; /* Not required for horizontal scroll to trigger */
    max-height: 100%; /* Needed for vertical scroll to trigger */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .simplebar-content-wrapper::-webkit-scrollbar,
  .simplebar-hide-scrollbar::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }

  .simplebar-content:before,
  .simplebar-content:after {
    content: ' ';
    display: table;
  }

  .simplebar-placeholder {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    pointer-events: none;
  }

  .simplebar-height-auto-observer-wrapper {
    box-sizing: inherit !important;
    height: 100%;
    width: 100%;
    max-width: 1px;
    position: relative;
    float: left;
    max-height: 1px;
    overflow: hidden;
    z-index: -1;
    padding: 0;
    margin: 0;
    pointer-events: none;
    flex-grow: inherit;
    flex-shrink: 0;
    flex-basis: 0;
  }

  .simplebar-height-auto-observer {
    box-sizing: inherit;
    display: block;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 1000%;
    width: 1000%;
    min-height: 1px;
    min-width: 1px;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
  }

  .simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;
  }

  [data-simplebar].simplebar-dragging .simplebar-content {
    pointer-events: none;
    user-select: none;
    -webkit-user-select: none;
  }

  [data-simplebar].simplebar-dragging .simplebar-track {
    pointer-events: all;
  }

  .simplebar-scrollbar {
    position: absolute;
    left: 0;
    right: 0;
    min-height: 10px;
  }

  .simplebar-scrollbar:before {
    position: absolute;
    content: '';
    background: black;
    border-radius: 7px;
    left: 2px;
    right: 2px;
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    /* When hovered, remove all transitions from drag handle */
    opacity: 0.5;
    transition: opacity 0s linear;
  }

  .simplebar-track.simplebar-vertical {
    top: 0;
    width: 11px;
  }

  .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
    top: 2px;
    bottom: 2px;
  }

  .simplebar-track.simplebar-horizontal {
    left: 0;
    height: 11px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
    height: 100%;
    left: 2px;
    right: 2px;
  }

  .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
    right: auto;
    left: 0;
    top: 2px;
    height: 7px;
    min-height: 0;
    min-width: 10px;
    width: auto;
  }

  /* Rtl support */
  [data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
    right: auto;
    left: 0;
  }

  .hs-dummy-scrollbar-size {
    direction: rtl;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    height: 500px;
    width: 500px;
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .simplebar-hide-scrollbar {
    position: fixed;
    left: 0;
    visibility: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .simplebar-scrollbar:before {
    background: ${({ colors }) => colors.grey[100]};
    width: 5px;
  }

  .simplebar-scrollbar.simplebar-visible:before {
    opacity: 1;
  }

  .simplebar-track {
    background: url('/images/scroller-base.png');
    background-repeat: repeat-y;
    background-position-x: -1px;
    border-radius: 8px;
    /* margin-right: -20px; */
    width: 5px;
  }
  
  .rc-slider-dot-active {
    border-color: ${({ colors }) => colors.primary};
  }

  .rc-slider-handle {
    opacity: 1;
  }

  .rc-slider-mark-text {
    color: ${({ colors }) => colors.grey[100]};
    min-width: 60px;
  }

  .rc-slider-mark-text-active {
    color: ${({ colors }) => colors.primary};
  }

  .rc-slider-dot {
    width: 12px;
    height: 12px;
    bottom: -4px;
    margin-left: -5px;
  }

  .rc-slider-handle:focus {
    box-shadow: none;
  }

  .rc-slider-tooltip-inner {
    min-width: 70px;
    background-color: ${({ colors }) => colors.primary};

  }

  .rc-tooltip {
    opacity: 1 !important;
    transition: all 0.3s ease-in-out;
  }

  .rc-tooltip-inner {
    background-color: ${({ colors }) => colors.primary};
    color: ${({ colors }) => colors.white};
    min-height: 18px;
  }

  .green .rc-tooltip-inner {
    background-color: ${({ colors }) => colors.green['1000']};
    color: ${({ colors }) => colors.white};
    min-height: 18px;
  }

  .red .rc-tooltip-inner {
    background-color: ${({ colors }) => colors.red['1000']};
    color: ${({ colors }) => colors.white};
    min-height: 18px;
  }

  .black .rc-tooltip-inner {
    background-color: ${({ colors }) => colors.black};
    color: ${({ colors }) => colors.white};
    min-height: 18px;
  }

  .rc-tooltip-placement-top .rc-tooltip-arrow, .rc-tooltip-placement-topLeft .rc-tooltip-arrow, .rc-tooltip-placement-topRight .rc-tooltip-arrow {
    border-top-color: ${({ colors }) => colors.primary};
  }

  .rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: ${({ colors }) => colors.primary};
  }

  .rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: ${({ colors }) => colors.primary};
  }

  .green .rc-tooltip-arrow {
    border-bottom-color: ${({ colors }) => colors.green['1000']};
  }

  .red .rc-tooltip-arrow {
    border-bottom-color: ${({ colors }) => colors.red['1000']};
  }

  .black .rc-tooltip-arrow {
    border-bottom-color: ${({ colors }) => colors.black};
  }

  .rc-tooltip-placement-top .rc-tooltip-content {
    margin-bottom: -5px;
  }
  
  .rc-tooltip-placement-top .rc-tooltip-arrow {
    margin-bottom: -5px;
  }

  input:focus ~ svg {
    background: inherit !important;
  }

  #app-notifications-right {
    /* pointer-events: none; */
    position: fixed;
    top: 10px;
    right: 10px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    z-index: 9999;
  }
  
  /* @media (min-width: ${({ breakpoints }) => breakpoints[1]}) {
    body {
      padding-bottom: 0;
    }
  } */

  /* @media (min-width: ${({ breakpoints }) => breakpoints[2]}) {
    html {
      font-size: 16px;
    }
  }  */
  
  @media (min-width: ${({ breakpoints }) => breakpoints[3]}) {
    body {
      padding-bottom: 0;
    }
  }
`

export default useDefaultTheme
